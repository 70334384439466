export const FETCH_LISTENER_ANALYTICS = "FETCH_LISTENER_ANALYTICS"
export const FETCH_LISTENER_ANALYTICS_SUCC = "FETCH_LISTENER_ANALYTICS_SUCC"
export const FETCH_LISTENER_ANALYTICS_FAIL = "FETCH_LISTENER_ANALYTICS_FAIL"
export const FETCH_LISTENER_DETAILS = "FETCH_LISTENER_DETAILS"
export const FETCH_LISTENER_DETAILS_SUCC = "FETCH_LISTENER_DETAILS_SUCC"
export const FETCH_LISTENER_DETAILS_FAIL = "FETCH_LISTENER_DETAILS_FAIL"
export const FETCH_CLIENT_ANALYTICS = "FETCH_CLIENT_ANALYTICS"
export const FETCH_CLIENT_ANALYTICS_SUCC = "FETCH_CLIENT_ANALYTICS_SUCC"
export const FETCH_CLIENT_ANALYTICS_FAIL = "FETCH_CLIENT_ANALYTICS_FAIL"
export const FETCH_CLIENT_DETAILS = "FETCH_CLIENT_DETAILS"
export const FETCH_CLIENT_DETAILS_SUCC = "FETCH_CLIENT_DETAILS_SUCC"
export const FETCH_CLIENT_DETAILS_FAIL = "FETCH_CLIENT_DETAILS_FAIL"
export const FETCH_DOCTOR_ANALYTICS = "FETCH_DOCTOR_ANALYTICS"
export const FETCH_DOCTOR_ANALYTICS_SUCC = "FETCH_DOCTOR_ANALYTICS_SUCC"
export const FETCH_DOCTOR_ANALYTICS_FAIL = "FETCH_DOCTOR_ANALYTICS_FAIL"
export const FETCH_DOCTOR_DETAILS = "FETCH_DOCTOR_DETAILS"
export const FETCH_DOCTOR_DETAILS_SUCC = "FETCH_DOCTOR_DETAILS_SUCC"
export const FETCH_DOCTOR_DETAILS_FAIL = "FETCH_DOCTOR_DETAILS_FAIL"
export const FETCH_SELECTED_TAB = "FETCH_SELECTED_TAB"