import {
    FETCH_COUNSELLOR_REQUEST,
    FETCH_COUNSELLOR_SUCCESS,
    FETCH_COUNSELLOR_FAILURE,
    FETCH_COUNSELLOR_TIME_TRACK,
    FETCH_COUNSELLOR_STATUS,
    FETCH_COUNSELLOR_VERIFY
  } from "./counsellorTypes"

  const initialState = {
    loading: false,
    counsellor: [],
    timeTracking: [],
    error: "",
    status: [],
    verify: []
  }

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COUNSELLOR_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case FETCH_COUNSELLOR_SUCCESS:
        return {
          ...state,
          loading: false,
          counsellor: action.payload || [],
          error: "",
        }
      case FETCH_COUNSELLOR_FAILURE:
        return {
          ...state,
          loading: false,
          counsellor: [],
          error: action.payload,
        }
      case FETCH_COUNSELLOR_TIME_TRACK:
        return {
          ...state,
          loading: false,
          timeTracking: action.payload || [],
          error: "",
        }
      case FETCH_COUNSELLOR_STATUS:
        return {
          ...state,
          loading: false,
          status: action.payload || [],
          error: "",
        }
      case FETCH_COUNSELLOR_VERIFY:
        return {
          ...state,
          loading: false,
          verify: action.payload || [],
          error: "",
        }
      default:
        return state
    }
  }

  export default reducer
