import {
  FETCH_DOCTORS_REQUEST,
  FETCH_DOCTORS_SUCCESS,
  FETCH_DOCTORS_FAILURE,
  UPDATE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAILURE,
} from "./doctorTypes"

const initialState = {
  loading: false,
  doctors: [],
  error: "",
  doctorUpdateMessage: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCTORS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        doctors: action.payload || [],
        error: "",
      }
    case FETCH_DOCTORS_FAILURE:
      return {
        ...state,
        loading: false,
        doctors: [],
        error: action.payload,
      }
    case UPDATE_DOCTOR_SUCCESS:
      console.log(action.payload)
      var updatedDoctor = action.payload
      var updatedDoctors = state.doctors.map((doc) =>
        doc.doctorId !== updatedDoctor.doctorId ? doc : updatedDoctor
      )
      console.log(updatedDoctors)
      return {
        ...state,
        doctors: updatedDoctors,
        doctorUpdateMessage: action.payload,
      }
    case UPDATE_DOCTOR_FAILURE:
      return {
        ...state,
        doctorUpdateMessage: action.payload,
      }
    default:
      return state
  }
}

export default reducer
