import { combineReducers } from "redux"
import DoctorReducer from "./doctor/doctorReducer"
import PatientReducer from "./patient/patientReducer"
import CounsellorReducer from "./counsellor/counsellorReducer"
import AppointmentReducer from "./appointment/appointmentReducer"
import OrganisationReducer from "./organisation/organisationReducer"
import LoginReducer from "./login/loginReducer"
import ClinicReducer from "./clinic/clinicReducer"
import HealthCareProfessionalReducer from "./healthCareProfessional/healthCareProfessionalReducer"
import WellnessVideosReducer from "./wellnessvideos/WellnessVideosReducer";
import StatsReducer from "./stats/statsReducer";
import analytics from "./analytics/analyticsReducer";

const appReducer = combineReducers({
  login: LoginReducer,
  doctors: DoctorReducer,
  patients: PatientReducer,
  counsellors: CounsellorReducer,
  appointments: AppointmentReducer,
  organisations: OrganisationReducer,
  clinics: ClinicReducer,
  healthcareprofessionals: HealthCareProfessionalReducer,
  wellnessvideos: WellnessVideosReducer,
  stats: StatsReducer,
  analytics: analytics
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
