export const FETCH_HEALTH_CARE_PROFESSIONALS_REQUEST =
  "FETCH_HEALTH_CARE_PROFESSIONALS_REQUEST"
export const FETCH_HEALTH_CARE_PROFESSIONALS_SUCCESS =
  "FETCH_HEALTH_CARE_PROFESSIONALS_SUCCESS"
export const FETCH_HEALTH_CARE_PROFESSIONALS_FAILURE =
  "FETCH_HEALTH_CARE_PROFESSIONALS_FAILURE"
export const UPDATE_HEALTH_CARE_PROFESSIONAL_SUCCESS =
  "UPDATE_HEALTH_CARE_PROFESSIONAL_SUCCESS"
export const UPDATE_HEALTH_CARE_PROFESSIONAL_FAILURE =
  "UPDATE_HEALTH_CARE_PROFESSIONAL_FAILURE"
export const FETCH_PROFESSION_TYPE_SUCCESS = "FETCH_PROFESSION_TYPE_SUCCESS"
export const FETCH_PROFESSION_TYPE_FAILURE = "FETCH_PROFESSION_TYPE_FAILURE"
