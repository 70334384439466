import {
  FETCH_ORGANISATIONS_REQUEST,
  FETCH_ORGANISATIONS_SUCCESS,
  FETCH_ORGANISATIONS_FAILURE,
  ADD_ORGANISATION_SUCCESS,
  ADD_ORGANISATION_FAILURE,
  ORGANISATION_TYPES,
} from "./organisationTypes"

const initialState = {
  loading: false,
  organisations: [],
  error: "",
  neworganisationSuccess: "",
  neworganisationError: "",
  organisationTypes: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANISATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        organisations: action.payload || [],
        error: "",
      }
    case FETCH_ORGANISATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        organisations: [],
        error: action.payload,
      }
    case ADD_ORGANISATION_SUCCESS:
      return {
        ...state,
        neworganisationSuccess: action.payload,
      }
    case ADD_ORGANISATION_FAILURE:
      return {
        ...state,
        neworganisationError: action.payload,
      }
    case ORGANISATION_TYPES:
      return {
        ...state,
        organisationTypes: action.payload,
      }
    default:
      return state
  }
}

export default reducer
