import {
  FETCH_APPOINTMENTS_REQUEST,
  FETCH_APPOINTMENTS_SUCCESS,
  FETCH_APPOINTMENTS_FAILURE,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
} from "./appointmentTypes"

const initialState = {
  loading: false,
  appointments: [],
  error: "",
  orderUpdateMessage: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPOINTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: action.payload || [],
        error: "",
      }
    case FETCH_APPOINTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        appointments: [],
        error: action.payload,
      }
    case UPDATE_APPOINTMENT_SUCCESS:
      var updatedAppointment = action.payload
      var updatedAppointments = state.appointments.map((appoi) =>
        appoi.orderSeqId !== updatedAppointment.orderSeqId
          ? appoi
          : updatedAppointment
      )
      return {
        ...state,
        appointments: updatedAppointments,
      }
    case UPDATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        orderUpdateMessage: action.payload,
      }
    default:
      return state
  }
}

export default reducer
