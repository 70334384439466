import {
  FETCH_HEALTH_CARE_PROFESSIONALS_REQUEST,
  FETCH_HEALTH_CARE_PROFESSIONALS_SUCCESS,
  FETCH_HEALTH_CARE_PROFESSIONALS_FAILURE,
  UPDATE_HEALTH_CARE_PROFESSIONAL_SUCCESS,
  UPDATE_HEALTH_CARE_PROFESSIONAL_FAILURE,
  FETCH_PROFESSION_TYPE_SUCCESS,
  FETCH_PROFESSION_TYPE_FAILURE,
} from "./healthCareProfessionalTypes"

const initialState = {
  loading: false,
  healthcareprofessionals: [],
  error: "",
  healthcareprofessionalUpdateMessage: "",
  professiontypelookups: [],
  professiontypeserror: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HEALTH_CARE_PROFESSIONALS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_HEALTH_CARE_PROFESSIONALS_SUCCESS:
      return {
        ...state,
        loading: false,
        healthcareprofessionals: action.payload || [],
        error: "",
      }
    case FETCH_HEALTH_CARE_PROFESSIONALS_FAILURE:
      return {
        ...state,
        loading: false,
        healthcareprofessionals: [],
        error: action.payload,
      }
    case UPDATE_HEALTH_CARE_PROFESSIONAL_SUCCESS:
      console.log(action.payload)
      var updatedHCProf = action.payload
      var updatedHCProfs = state.healthcareprofessionals.map((hcp) =>
        hcp.professionalId !== updatedHCProf.professionalId
          ? hcp
          : updatedHCProf
      )
      return {
        ...state,
        healthcareprofessionals: updatedHCProfs,
      }
    case UPDATE_HEALTH_CARE_PROFESSIONAL_FAILURE:
      return {
        ...state,
        healthcareprofessionalUpdateMessage: action.payload,
      }
    case FETCH_PROFESSION_TYPE_SUCCESS:
      return {
        ...state,
        professiontypelookups: action.payload,
      }
    case FETCH_PROFESSION_TYPE_FAILURE:
      return {
        ...state,
        professiontypeserror: action.payload,
      }
    default:
      return state
  }
}

export default reducer
