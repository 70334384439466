import {
    FETCH_LISTENER_ANALYTICS,
    FETCH_LISTENER_ANALYTICS_SUCC,
    FETCH_LISTENER_ANALYTICS_FAIL,
    FETCH_LISTENER_DETAILS,
    FETCH_LISTENER_DETAILS_SUCC,
    FETCH_LISTENER_DETAILS_FAIL,
    FETCH_CLIENT_ANALYTICS,
    FETCH_CLIENT_ANALYTICS_SUCC,
    FETCH_CLIENT_ANALYTICS_FAIL,
    FETCH_CLIENT_DETAILS,
    FETCH_CLIENT_DETAILS_SUCC,
    FETCH_CLIENT_DETAILS_FAIL,
    FETCH_DOCTOR_ANALYTICS,
    FETCH_DOCTOR_ANALYTICS_SUCC,
    FETCH_DOCTOR_ANALYTICS_FAIL,
    FETCH_DOCTOR_DETAILS,
    FETCH_DOCTOR_DETAILS_SUCC,
    FETCH_DOCTOR_DETAILS_FAIL,
    FETCH_SELECTED_TAB,
} from "./analyticsTypes"

const initialState = {
    loading: false,
    error: "",
    listenerAnalytics: [],
    listenerDetails: [],
    clientAnalytics: [],
    clientDetails: [],
    doctorAnalytics: [],
    doctorDetails: [],
    selectedTab: "LISTENERS"
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LISTENER_ANALYTICS:
            return {
                ...state,
                loading: true,
            }
        case FETCH_LISTENER_ANALYTICS_SUCC:
            return {
                ...state,
                loading: false,
                listenerAnalytics: action.payload || [],
                error: "",
            }
        case FETCH_LISTENER_ANALYTICS_FAIL:
            return {
                ...state,
                loading: false,
                listenerAnalytics: [],
                error: action.payload,
            }
        case FETCH_LISTENER_DETAILS:
            return {
                ...state,
                loading: true,
            }
        case FETCH_LISTENER_DETAILS_SUCC:
            return {
                ...state,
                loading: false,
                listenerDetails: action.payload || [],
                error: "",
            }
        case FETCH_LISTENER_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                listenerDetails: [],
                error: action.payload,
            }
        case FETCH_CLIENT_ANALYTICS:
            return {
                ...state,
                loading: true,
            }
        case FETCH_CLIENT_ANALYTICS_SUCC:
            return {
                ...state,
                loading: false,
                clientAnalytics: action.payload || [],
                error: "",
            }
        case FETCH_CLIENT_ANALYTICS_FAIL:
            return {
                ...state,
                loading: false,
                clientAnalytics: [],
                error: action.payload,
            }
        case FETCH_CLIENT_DETAILS:
            return {
                ...state,
                loading: true,
            }
        case FETCH_CLIENT_DETAILS_SUCC:
            return {
                ...state,
                loading: false,
                clientDetails: action.payload || [],
                error: "",
            }
        case FETCH_CLIENT_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                clientDetails: [],
                error: action.payload,
            }
        case FETCH_DOCTOR_ANALYTICS:
            return {
                ...state,
                loading: true,
            }
        case FETCH_DOCTOR_ANALYTICS_SUCC:
            return {
                ...state,
                loading: false,
                doctorAnalytics: action.payload || [],
                error: "",
            }
        case FETCH_DOCTOR_ANALYTICS_FAIL:
            return {
                ...state,
                loading: false,
                doctorAnalytics: [],
                error: action.payload,
            }
        case FETCH_DOCTOR_DETAILS:
            return {
                ...state,
                loading: true,
            }
        case FETCH_DOCTOR_DETAILS_SUCC:
            return {
                ...state,
                loading: false,
                doctorDetails: action.payload || [],
                error: "",
            }
        case FETCH_DOCTOR_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                doctorDetails: [],
                error: action.payload,
            }
        case FETCH_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.payload
            }
        default:
            return state
    }
}

export default reducer