import {
  FETCH_CLINICS_REQUEST,
  FETCH_CLINICS_SUCCESS,
  FETCH_CLINICS_FAILURE,
  INSERT_OR_UPDATE_CLINIC_SUCCESS,
  ADD_CLINIC_SUCCESS,
  ADD_CLINIC_FAILURE,
} from "./clinicTypes"

const initialState = {
  loading: false,
  clinics: [],
  error: "",
  // neworganisationSuccess: "",
  // neworganisationError: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLINICS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_CLINICS_SUCCESS:
      return {
        ...state,
        loading: false,
        clinics: action.payload || [],
        error: "",
      }
    case FETCH_CLINICS_FAILURE:
      return {
        ...state,
        loading: false,
        clinics: [],
        error: action.payload,
      }
    case INSERT_OR_UPDATE_CLINIC_SUCCESS:
      var updatedClinic = action.payload
      var updatedClinics = state.clinics.map((cli) =>
        cli.clinicId !== updatedClinic.clinicId ? cli : updatedClinic
      )
      return {
        ...state,
        clinics: updatedClinics,
      }
    // case ADD_CLINIC_FAILURE:
    //   return {
    //     ...state,
    //     neworganisationError: action.payload,
    //   }
    default:
      return state
  }
}

export default reducer
