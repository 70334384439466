import {
  FETCH_PATIENTS_REQUEST,
  FETCH_PATIENTS_SUCCESS,
  FETCH_PATIENTS_FAILURE,
  FETCH_SECONDARY_PATIENTS,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAILURE,
} from "./patientTypes"

const initialState = {
  loading: false,
  patients: [],
  secondaryPatients: [],
  error: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PATIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        patients: action.payload || [],
        error: "",
      }
    case FETCH_PATIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        patients: [],
        error: action.payload,
      }
    case FETCH_SECONDARY_PATIENTS:
      // console.log(action.payload)
      return {
        ...state,
        secondaryPatients: action.payload,
      }
    case UPDATE_PATIENT_SUCCESS:
      var updatedPatient = action.payload
      var updatedPatients = state.patients.map((pati) =>
        pati.patientId !== updatedPatient.patientId ? pati : updatedPatient
      )
      return {
        ...state,
        patients: updatedPatients,
      }
    case UPDATE_PATIENT_FAILURE:
      return {
        ...state,
      }
    default:
      return state
  }
}

export default reducer
