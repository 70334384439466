// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analytics-analytics-display-container-js": () => import("./../../../src/pages/analytics/analyticsDisplayContainer.js" /* webpackChunkName: "component---src-pages-analytics-analytics-display-container-js" */),
  "component---src-pages-analytics-analytics-tab-js": () => import("./../../../src/pages/analytics/analyticsTab.js" /* webpackChunkName: "component---src-pages-analytics-analytics-tab-js" */),
  "component---src-pages-analytics-client-analytic-js": () => import("./../../../src/pages/analytics/clientAnalytic.js" /* webpackChunkName: "component---src-pages-analytics-client-analytic-js" */),
  "component---src-pages-analytics-doctor-analytic-js": () => import("./../../../src/pages/analytics/doctorAnalytic.js" /* webpackChunkName: "component---src-pages-analytics-doctor-analytic-js" */),
  "component---src-pages-analytics-index-js": () => import("./../../../src/pages/analytics/index.js" /* webpackChunkName: "component---src-pages-analytics-index-js" */),
  "component---src-pages-analytics-listener-analytic-js": () => import("./../../../src/pages/analytics/listenerAnalytic.js" /* webpackChunkName: "component---src-pages-analytics-listener-analytic-js" */),
  "component---src-pages-appointments-appointment-js": () => import("./../../../src/pages/appointments/appointment.js" /* webpackChunkName: "component---src-pages-appointments-appointment-js" */),
  "component---src-pages-appointments-index-js": () => import("./../../../src/pages/appointments/index.js" /* webpackChunkName: "component---src-pages-appointments-index-js" */),
  "component---src-pages-clinics-clinic-js": () => import("./../../../src/pages/clinics/clinic.js" /* webpackChunkName: "component---src-pages-clinics-clinic-js" */),
  "component---src-pages-clinics-index-js": () => import("./../../../src/pages/clinics/index.js" /* webpackChunkName: "component---src-pages-clinics-index-js" */),
  "component---src-pages-counsellors-counsellor-js": () => import("./../../../src/pages/counsellors/counsellor.js" /* webpackChunkName: "component---src-pages-counsellors-counsellor-js" */),
  "component---src-pages-counsellors-index-js": () => import("./../../../src/pages/counsellors/index.js" /* webpackChunkName: "component---src-pages-counsellors-index-js" */),
  "component---src-pages-doctors-doctor-js": () => import("./../../../src/pages/doctors/doctor.js" /* webpackChunkName: "component---src-pages-doctors-doctor-js" */),
  "component---src-pages-doctors-index-js": () => import("./../../../src/pages/doctors/index.js" /* webpackChunkName: "component---src-pages-doctors-index-js" */),
  "component---src-pages-healthcareprofessionals-healthcareprofessional-js": () => import("./../../../src/pages/healthcareprofessionals/healthcareprofessional.js" /* webpackChunkName: "component---src-pages-healthcareprofessionals-healthcareprofessional-js" */),
  "component---src-pages-healthcareprofessionals-index-js": () => import("./../../../src/pages/healthcareprofessionals/index.js" /* webpackChunkName: "component---src-pages-healthcareprofessionals-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-notifications-index-js": () => import("./../../../src/pages/notifications/index.js" /* webpackChunkName: "component---src-pages-notifications-index-js" */),
  "component---src-pages-organisations-index-js": () => import("./../../../src/pages/organisations/index.js" /* webpackChunkName: "component---src-pages-organisations-index-js" */),
  "component---src-pages-organisations-neworganisation-js": () => import("./../../../src/pages/organisations/neworganisation.js" /* webpackChunkName: "component---src-pages-organisations-neworganisation-js" */),
  "component---src-pages-organisations-organisation-js": () => import("./../../../src/pages/organisations/organisation.js" /* webpackChunkName: "component---src-pages-organisations-organisation-js" */),
  "component---src-pages-patients-index-js": () => import("./../../../src/pages/patients/index.js" /* webpackChunkName: "component---src-pages-patients-index-js" */),
  "component---src-pages-patients-patient-js": () => import("./../../../src/pages/patients/patient.js" /* webpackChunkName: "component---src-pages-patients-patient-js" */),
  "component---src-pages-wellnessvideos-index-js": () => import("./../../../src/pages/wellnessvideos/index.js" /* webpackChunkName: "component---src-pages-wellnessvideos-index-js" */)
}

