import {
    FETCH_APPOINTMENTS_COUNT,
    FETCH_APPOINTMENTS_COUNT_SUCCESS,
    FETCH_APPOINTMENTS_COUNT_FAILURE,
    FETCH_REQUEST_COUNT,
    FETCH_REQUEST_COUNT_SUCCESS,
    FETCH_REQUEST_COUNT_FAILURE,
    FETCH_VIDEO_VIEW_COUNT,
    FETCH_VIDEO_VIEW_COUNT_SUCCESS,
    FETCH_VIDEO_VIEW_COUNT_FAILURE,
    FETCH_VIDEO_VIEW_CATEGORY_COUNT,
    FETCH_LISTENER_HISTORY,
    FETCH_MOOD_HISTORY,
} from "./statsTypes"

const initialState = {
    loading: false,
    aptCountData: {},
    reqCountData: {},
    vidViewCountData: {},
    error: "",
    vidCategoryCountData: {},
    listenerHistory: {},
    moodHistory: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_APPOINTMENTS_COUNT:
            return {
                ...state,
                loading: true,
            }
        case FETCH_APPOINTMENTS_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                aptCountData: action.payload || {},
                error: "",
            }
        case FETCH_APPOINTMENTS_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                aptCountData: {},
                error: action.payload,
            }
        case FETCH_REQUEST_COUNT:
            return {
                ...state,
                loading: true,
            }
        case FETCH_REQUEST_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                reqCountData: action.payload || {},
                error: "",
            }
        case FETCH_REQUEST_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                reqCountData: {},
                error: action.payload,
            }
        case FETCH_VIDEO_VIEW_COUNT:
            return {
                ...state,
                loading: true,
            }
        case FETCH_VIDEO_VIEW_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                vidViewCountData: action.payload || {},
                error: "",
            }
        case FETCH_VIDEO_VIEW_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                vidViewCountData: {},
                error: action.payload,
            }
        case FETCH_VIDEO_VIEW_CATEGORY_COUNT:
            return {
                ...state,
                loading: false,
                vidCategoryCountData: action.payload || {},
                error: "",
            }
        case FETCH_LISTENER_HISTORY:
            return {
                ...state,
                loading: false,
                listenerHistory: action.payload || {},
                error: "",
            }
        case FETCH_MOOD_HISTORY:
            return {
                ...state,
                loading: false,
                moodHistory: action.payload || {},
                error: "",
            }
        default:
            return state
    }
}

export default reducer
