export const FETCH_APPOINTMENTS_COUNT = "FETCH_APPOINTMENTS_COUNT"
export const FETCH_APPOINTMENTS_COUNT_SUCCESS = "FETCH_APPOINTMENTS_COUNT_SUCCESS"
export const FETCH_APPOINTMENTS_COUNT_FAILURE = "FETCH_APPOINTMENTS_COUNT_FAILURE"
export const FETCH_REQUEST_COUNT = "FETCH_REQUEST_COUNT"
export const FETCH_REQUEST_COUNT_SUCCESS = "FETCH_REQUEST_COUNT_SUCCESS"
export const FETCH_REQUEST_COUNT_FAILURE = "FETCH_REQUEST_COUNT_FAILURE"
export const FETCH_VIDEO_VIEW_COUNT = "FETCH_VIDEO_VIEW_COUNT"
export const FETCH_VIDEO_VIEW_COUNT_SUCCESS = "FETCH_VIDEO_VIEW_COUNT_SUCCESS"
export const FETCH_VIDEO_VIEW_COUNT_FAILURE = "FETCH_VIDEO_VIEW_COUNT_FAILURE"
export const FETCH_VIDEO_VIEW_CATEGORY_COUNT = "FETCH_VIDEO_VIEW_CATEGORY_COUNT"
export const FETCH_LISTENER_HISTORY = "FETCH_LISTENER_HISTORY"
export const FETCH_MOOD_HISTORY = "FETCH_MOOD_HISTORY"